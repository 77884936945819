@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttons-container{display:flex;flex-direction:column;justify-content:center;align-items:center;width:80%;max-width:50rem}@media screen and (min-width: 800px){.buttons-container{flex-direction:row;height:10em;justify-content:space-around;align-items:center}}.big-button{width:100%;height:5rem;font-family:"DM Mono",monospace;text-transform:uppercase;border:none;cursor:pointer;margin:1rem;font-size:2rem;border-radius:15px;background-color:#ffad3b;color:#fffdef}.big-button:hover{border:1px solid #fff;color:#fff}.big-button:active{-webkit-transform:scale(0.98) translate(0%, -2.5%);transform:scale(0.98) translate(0%, -2.5%)}@media screen and (min-width: 800px){.big-button{width:15rem;height:10rem;font-size:2rem}}
body{color:#2a2829;font-family:"DM Mono",monospace;color:#fff}.container{display:flex;flex-flow:column nowrap;align-items:center;justify-content:flex-start;background-color:#626261;height:100vh}.header{display:flex;letter-spacing:1rem;padding:0 0 0 1rem;justify-content:center;border-radius:10px;align-items:center;background-color:#f23747;width:92%;font-size:4rem;margin:1rem 0 2rem 0;color:#fffdef}@media screen and (min-width: 800px){.header{letter-spacing:3rem;width:92%;max-width:55rem;margin:5rem 0 5rem 0}}.footer{display:flex;justify-content:space-between;padding:0rem 1rem 0rem 1rem;border-radius:10px;width:80%;margin:8rem 0 1rem 0;align-items:flex-end;background-color:#5068ec;font-size:1rem}@media screen and (min-width: 800px){.footer{width:92%;max-width:50rem;margin:10rem 0 0 0}}a{color:#fff}
